import { CompanyService } from 'src/app/core/services/company.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { UserService } from './user.service';
import { AuthProvider, EmailAuthProvider, OAuthProvider, UserCredential, fetchSignInMethodsForEmail, getAuth, linkWithCredential, onAuthStateChanged, signInWithCredential, signInWithEmailAndPassword, signInWithPopup, signOut } from '@angular/fire/auth';
import { User } from '../models/user';
import { MsspService } from '@core/tenable/service/mssp.service';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { Company } from '@core/models/company';
import { environment } from 'src/environments/environment';
import { TenableChildAccount } from '@core/tenable/models/tenable-child-account';
import { blockedDomains } from 'src/app/blockedDomains';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {

  isLoggedIn = false;
  userModel?: User;
  userData?: User;
  redirectUrl: string | null = null;

  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentUser!: Observable<any>;
  public accountExpired: boolean | null = null;
  private isGettingScanners = false;
  private company_slug?: string;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private msspService: MsspService,
    private errorHandler: ErrorHandlerService
  ) {

    this.checkAuthChange();
    this.checkIfLoggedIn();
  }


  ngOnDestroy() {
    if (this.currentUserSubject) {
      this.currentUserSubject.unsubscribe();
    }
  }

  /**
   * Checks for changes in Firebase Authentication state.
   * If a user is logged in, it creates or fetches their details.
   * If no user is logged in, it resets user details and authentication state.
   */
  checkAuthChange() {
    onAuthStateChanged(getAuth(), async (user: any) => {
      if (user) {
        const userModel = new User(user);
        const userObject = await this.userService.getOrCreateUserModel(userModel);
        this.companyService.user = userObject;
        this.userData = userObject;
        this.isLoggedIn = true;
      } else {
        this.removeUserSession();
      }
    });
  }

  /**
   * Checks if the user has an associated company.
   * @returns A boolean indicating whether the user has a company.
   */
  hasCompany(): boolean {
    if (!this.userModel) {
      this.errorHandler.handleError('User model not found');
      console.error('User model not found');
      return false;
    }
    this.companyService.user = this.userModel;
    this.companyService.setUserProfile();
    return this.companyService.checkIfUserHasCompany();
  }

  /**
   * Signs out the user and removes user session from sessionStorage.
   * @returns A promise that resolves when the user is signed out.
   */
  async signOutUser(): Promise<void> {
    sessionStorage.clear();
    localStorage.clear();
    return await signOut(getAuth());
  }

  /**
   * Runs authentication providers (Google, Microsoft, NOT EMAIL/PASS) for login.
   * @param provider The authentication provider.
   * @returns A promise that resolves to an object with a success property indicating the success of the login.
   */
  async AuthLogin(provider: AuthProvider) {
    try {
      const res: UserCredential = await signInWithPopup(getAuth(), provider);
      if (res.user?.email) {
        const emailDomain = res.user.email.split('@')[1].toLowerCase();

        if (blockedDomains.includes(emailDomain)) {
          console.error('Login using public email providers is not allowed.');
          await this.signOutUser();
          return { success: false, error: 'Login using public email providers is not allowed.' };
        }
      }

      const success = this.signinUser(res);
      return { success, userCred: res };
    } catch (error: any) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        await this.handleAccountExists(error.customData);
      }
      console.error(error);
      return { success: false, error };
    }
  }

  /**
   * Handles merging of accounts that exist in Firebase with different credentials.
   * @param details The error details containing the existing email.
   */
  async handleAccountExists(details: any) {
    const email = details.email;
    const auth = getAuth();

    try {
      // eslint-disable-next-line
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      const password = prompt('Please enter your password to link the accounts');
      if (!password) {
        return;
      }
      const emailCredential = EmailAuthProvider.credential(email, password);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const oauthCredential = await OAuthProvider.credentialFromResult(userCredential);

      const result = await signInWithCredential(auth, emailCredential);

      // eslint-disable-next-line
      const currentUser = result.user;

      const prevUser = auth.currentUser;
      if (!prevUser) {
        throw new Error('No previous user found');
      }

      const credential = OAuthProvider.credentialFromResult(result);
      if (!credential) {
        throw new Error('No credential found');
      }

      const linkResult = await linkWithCredential(prevUser, credential);
      const linkCredential = OAuthProvider.credentialFromResult(linkResult);
      if (!linkCredential) {
        throw new Error('No link credential found');
      }

      // eslint-disable-next-line
      const signInResult = await signInWithCredential(auth, linkCredential);
      // Save the merged data to the new user
      // repo.set(signInResult.user, mergedData);

      if (oauthCredential) {
        await linkWithCredential(userCredential.user, oauthCredential);
      }
    } catch (error) {
      console.error('Error merging accounts:', error);
      this.errorHandler.handleError(error);
    }
  }

  /**
   * Signs in the user and checks if the user has a company profile and a selected plan.
   * @param userCred The user credential.
   * @returns A boolean indicating whether the user was successfully signed in.
   */
  private signinUser(userCred: UserCredential): boolean {
    const userModel = new User(userCred.user);
    this.userData = userModel;
    this.userService.getOrCreateUserModel(userModel);
    if (userModel) {
      this.isLoggedIn = true;
      return true;
    }
    return false;
  }

  /**
   * Checks if the user is logged in and has user data.
   * @returns A boolean indicating whether the user is logged in.
   */
  checkIfLoggedIn(): boolean {
    return this.isLoggedIn && !!this.userData;
  }

  /**
   * Sets the user session in sessionStorage.
   * @param userModel The user model to be stored in the session.
   */
  setUserSession(userModel: User) {
    sessionStorage.setItem('user', JSON.stringify(userModel));
  }

  /**
   * Retrieves the user session from sessionStorage.
   * @returns The user model if it exists, otherwise false.
   */
  async getUserSession() {
    const currentUser = sessionStorage.getItem('user');
    if (currentUser) {
      const userObject = JSON.parse(currentUser);
      const userModel = await this.userService.getDocument(userObject.uid);
      if (userModel) {
        this.userModel = new User(userModel);
        this.userService.user = this.userModel;
        this.companyService.user = this.userModel;
        this.currentUserSubject.next(userModel);
        this.isLoggedIn = true;
        return this.userModel;
      }
    }
    this.isLoggedIn = false;
    return false;
  }

  /**
   * Removes the user session from sessionStorage.
   */
  removeUserSession() {
    this.isLoggedIn = false;
    // this.userData = null;
    sessionStorage.removeItem('user');
  }

  /**
   * Checks if the account is expired via the Tenable service scanner list call.
   * If the account is expired, sets an error message in session storage and returns false.
   * Otherwise, returns true.
   * @param user The user object to check.
   * @returns {Promise<boolean>} Promise resolving to true if the account is not expired, false otherwise.
   */
  async isAccountExpired(user: User): Promise<boolean> {
    if (!user) return new Promise((resolve) => {
      this.errorHandler.handleError('User not found');
      resolve(true);
    });

    const storedAccountExpired = sessionStorage.getItem('accountExpired');
    if (storedAccountExpired !== null) {
      this.accountExpired = storedAccountExpired === 'true';
      return this.accountExpired;
    }

    if (this.accountExpired !== null) {
      return this.accountExpired;
    }

    if (this.isGettingScanners) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!this.isGettingScanners) {
            clearInterval(interval);
            resolve(this.accountExpired ?? false);
          }
        }, 100);
      });
    }

    try {
      const auth = getAuth();
      if (!auth.currentUser) {
        return false;
      }
      this.isGettingScanners = true;
      this.userService.setUserModel(user);
      this.userModel = user;
      this.companyService.setUserProfile();

      const companyModel = await this.companyService.getDocument(this.userModel.companyId);
      if (!companyModel) {
        this.accountExpired = true;
        this.isGettingScanners = false;
        return this.accountExpired;
      }
      this.companyService.company = new Company(companyModel);

      await firstValueFrom(this.companyService.getCompanySlug()).then(slug => {
        if (slug) {
          this.company_slug = slug;
          this.msspService.companySlug = this.company_slug;
        }
      });

      const containerCompanyName = environment.production ? companyModel.uid : companyModel.uid + '-dev';
      const accountDetails = JSON.parse(sessionStorage.getItem('accountDetails') || '{}');
      if (accountDetails && Object.keys(accountDetails).length > 0) {
        const validAccount = this.msspService.validateAccount(accountDetails, companyModel);
        this.accountExpired = !validAccount;
      } else {
        const accountRes = await firstValueFrom(this.msspService.listChildAccounts());
        if (!accountRes || accountRes.accounts.length === 0) {
          const createdAccount = await this.msspService.createMsspAccount(companyModel, this.userModel);
          this.accountExpired = !createdAccount;
          if (createdAccount) {
            const accountDetails = new TenableChildAccount(createdAccount);
            this.companyService.updateCompanyAccountDetails(accountDetails);
          }
        } else {
          const accountModel = accountRes.accounts.find((accountData: TenableChildAccount) => accountData.container_name === containerCompanyName);
          if (!accountModel) {
            const accountCreated = await this.msspService.createMsspAccount(companyModel, this.userModel);
            this.accountExpired = !accountCreated;
            if (accountCreated) {
              const accountDetails = new TenableChildAccount(accountCreated);
              this.companyService.updateCompanyAccountDetails(accountDetails);
            }
          } else {
            this.accountExpired = !this.msspService.validateAccount(accountModel, companyModel);
          }
        }
      }

      sessionStorage.setItem('accountExpired', this.accountExpired ? 'true' : 'false');
      this.isGettingScanners = false;
      if (this.accountExpired) {
        const accountError = {
          message: "Your account has expired. Please contact support for assistance."
        };
        sessionStorage.setItem('accountError', JSON.stringify(accountError));
        this.accountExpired = true;
      }
      return this.accountExpired;
    } catch (error: any) {
      // Handle errors and assume the account is not expired
      console.error("An unexpected error occurred:", error);
      this.errorHandler.handleError(error);
      this.accountExpired = false;
      // sessionStorage.setItem('accountExpired', 'false');
      this.isGettingScanners = false;
      return this.accountExpired;
    }
  }

  /**
   * Logs out the current user by calling signOutUser.
   */
  logoutUser() {
    this.signOutUser();
  }
}

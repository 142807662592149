
export const blockedDomains = [
  'gmail.com', 'outlook.com', 'yahoo.com', 'hotmail.com', 'aol.com',
  'icloud.com', 'mail.com', 'zoho.com', 'protonmail.com', 'gmx.com',
  'yandex.com', 'qq.com', 'naver.com', 'mail.ru', 'inbox.com',
  'live.com', 'msn.com', 'me.com', 'comcast.net', 'verizon.net',
  'att.net', 'bellsouth.net', 'charter.net', 'cox.net', 'earthlink.net',
  'sbcglobal.net', 'shaw.ca', 'rogers.com', 'sympatico.ca', 'btinternet.com',
  'sky.com', 'blueyonder.co.uk', 'ntlworld.com', 't-online.de', 'web.de',
  '163.com', '126.com', 'sina.com', 'daum.net', 'hanmail.net', 'yeah.net',
  'rediffmail.com', 'indiatimes.com', 'libero.it', 'virgilio.it', 'tiscali.it',
  'alice.it', 'wanadoo.fr', 'orange.fr', 'laposte.net', 'free.fr', 'neuf.fr',
  'seznam.cz', 'centrum.cz', 'o2.pl', 'wp.pl', 'onet.pl', 'interia.pl',
  'abv.bg', 'ukr.net', 'list.ru', 'bk.ru', 'rambler.ru', 'bigpond.com',
  'optusnet.com.au', 'internode.on.net', 'biglobe.ne.jp', 'plala.or.jp',
  'ybb.ne.jp', 'hotmail.co.uk', 'hotmail.fr', 'hotmail.it', 'hotmail.de',
  'live.co.uk', 'live.fr', 'live.de', 'live.nl', 'live.it', 'live.com.au'
];
